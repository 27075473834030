<template>
  <div class="status" :class="{ inverted: inverted }">
    <span class="state" :class="statusColorClass" v-if="!loading && label">
      <div class="p-tag" :class="{ 'p-text-nowrap': !wrap }">{{ label }}</div>
    </span>
    <Skeleton v-if="loading" width="13ch" height="1.2em" />
  </div>
</template>

<script>
import Skeleton from "primevue/skeleton";

export default {
  name: "Status",
  components: {
    Skeleton,
  },
  props: {
    value: Number,
    label: String,
    inverted: Boolean,
    wrap: Boolean,
  },
  computed: {
    loading() {
      return !Number.isInteger(this.value);
    },
    statusColorClass() {
      switch (this.value) {
        case 0:
        default:
          return "not-set";
        case 5:
        case 7:
          return "request-submitted";
        case 4:
        case 14:
        case 17:
        case 19:
          return "product-requirements";
        case 15:
        case 18:
          return "legal-review";
        case 11:
        case 16:
          return "structuring";
        case 2:
        case 12:
        case 13:
          return "primary-subscription";
        case 1:
        case 3:
        case 9:
          return "issued";
        case 6:
          return "terminated";
      }
    },
  },
};
</script>

<style scoped lang="scss">
@use "sass:color";

$color1: #ffa400;
$color2: #b2ad8f;
$color3: #e89500;
$color4: #f56bf9;
$color5: #3dd281;
$color6: #0dc761;
$color7: #e42e3a;

.status {
  --color1: #{$color1};
  --color2: #{$color2};
  --color3: #{$color3};
  --color4: #{$color4};
  --color5: #{$color5};
  --color6: #{$color6};
  --color7: #{$color7};

  display: flex;
  align-items: center;
  flex: 0;
  position: relative;
  font-size: 1.125em;

  .state {
    flex: 1;
    text-align: center;

    .p-tag {
      border: 1px solid transparent;
      max-width: 14ch;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &.not-set {
      .p-tag {
        background: transparent;
        color: var(--primary-color);
      
      }
    }
    &.request-submitted {
      .p-tag {
        background: #{color.adjust($color1, $alpha: -0.94)};
        color: $color1;
      }
    }
    &.product-requirements {
      .p-tag {
        background: #{color.adjust($color1, $alpha: -0.94)};
        color: $color1;
      }
    }
    &.legal-review {
      .p-tag {
        background: #{color.adjust($color3, $alpha: -0.94)};
        color: $color3;
      }
    }
    &.structuring {
      .p-tag {
        background: #{color.adjust($color4, $alpha: -0.94)};
        color: $color4;
      }
    }
    &.primary-subscription {
      .p-tag {
        background: #{color.adjust($color6, $alpha: -0.9)};
        color: $color6;
      }
    }
    &.issued {
      .p-tag {
        background: #{color.adjust($color6, $alpha: -0.94)};
        color: $color6;
      }
    }
    &.not-set {
      background: #{color.adjust(#fb5c66, $alpha: -0.94)};
      color: #fb5c66;
    }
    &.terminated {
      .p-tag {
        background: #{color.adjust($color7, $alpha: -0.94)};
        color: $color7;
      }
    }
  }

  &.inverted {
    .state {
      &.request-submitted {
        .p-tag {
          background: var(--color1);
          color: var(--text-color-secondary);
        }
      }
      &.product-requirements {
        .p-tag {
          background: var(--color2);
        }
      }
      &.legal-review {
        .p-tag {
          background: var(--color3);
        }
      }
      &.structuring {
        .p-tag {
          background: var(--color4);
        }
      }
      &.primary-subscription {
        .p-tag {
          background: var(--color5);
        }
      }
      &.issued {
        .p-tag {
          background: var(--color6);
        }
      }
    }
  }
}
.p-tag {
  display: block;
  overflow: hidden;
  color: var(--text-color-inverted);
  border-radius: var(--border-radius-sm);
}
</style>
